<!--企业微信管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c  flex-wrap">
                <label>场景：</label>
                <el-select v-model="select.status" placeholder="选择直播状态" filterable clearable class="selectWidth" @change="curr=1;getList()">
                    <el-option v-for="(item,index) in selectData" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
                <el-date-picker v-model="select.living_start" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker>
                <el-input placeholder="请输入视频名称" v-model="select.searchValue" size="small" style="width:300px;" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="theme" label="视频名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user_name" label="直播人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="living_start" label="直播时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="living_duration" label="播放时长(秒)" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status_name" label="直播状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span :class="[scope.row.status == 0 ? 'primaryColor' : scope.row.status == 1 ? 'successColor' : scope.row.status == 2 ? 'errorColor' : scope.row.status == 3 ? 'warningColor' :  'infoColor']">{{scope.row.status_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="online_count" label="观看人数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="subscribe_count" label="直播预约数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {wechatListApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[{
                    id:0,
                    name:'预约中'
                },{
                    id:1,
                    name:'直播中'
                },{
                    id:2,
                    name:'已结束'
                },{
                    id:3,
                    name:'已过期'
                },{
                    id:4,
                    name:'已取消'
                }],//下拉选择列表
                list:[], //直播列表

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.getList();
        },
        methods: {

            //企业微信直播列表
            getList: function(){
                wechatListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
           
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>